import React from "react";
import { ISearchResults } from "../types";
import { useSelector } from "react-redux";
import ProfilePhoto from "../ProfilePhoto";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useImage } from "../../services/getImages";

const SearchResults: React.FC<ISearchResults> = ({
  data,
  next,
  close,
  dataLength,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  const handleClick = (res: any) => {
    close();
    if (res?.id === user?.id) {
      navigate("/app/dashboard/home");
    } else {
      navigate(
        `/app${res?.type === "game" ? "/games" : ""}/${res?.type}${
          res?.type === "team" ? "s" : ""
        }/${res?.id}${res?.type === "user" ? "/posts" : ""}`,
      );
    }
  };

  return (
    <div className={styles.search_results_wrapper}>
      <div className={styles.results_header}>
        <p className={styles.header_text}>Results</p>
      </div>

      <div id="scrollableDiv" className={styles.results_wrapper}>
        <InfiniteScroll
          next={next}
          loader={<></>}
          endMessage={<></>}
          dataLength={data?.length}
          scrollableTarget="scrollableDiv"
          hasMore={dataLength > data?.length}
        >
          {data?.length && data?.[0] ? (
            data?.map((e: any) => {
              return (
                <Card
                  e={e}
                  key={e?.id}
                  handleClick={(e: any) => handleClick(e)}
                />
              );
            })
          ) : (
            <div className={styles.no_results_wrapper}>
              <p className={styles.no_results_text}>No Results</p>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const Card = ({ e, handleClick }: any) => {
  const { data: eImage } = useImage(e?.image);
  return (
    <div key={e?.id} className={styles.result} onClick={() => handleClick(e)}>
      <ProfilePhoto rounded={true} img={e?.image ? eImage : ""} size="32px" />

      <div className={styles.profile_info_wrapper}>
        <p className={styles.profile_name}>{e?.name}</p>
        <p className={styles.username}>
          {e?.type?.split("")[0].toUpperCase()}
          {e?.type?.slice(1)}
        </p>
      </div>
    </div>
  );
};

export default SearchResults;
